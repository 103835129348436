@keyframes blink {
    0% { padding: 18px; border-width: 1px; }
    25% { padding: 20px; border-width: 2px; }
    50% { padding: 23px; border-width: 3px; }
    75% { padding: 20px; border-width: 2px; }
    100% { padding: 18px; border-width: 1px; }
  }
  
  .blink {
    animation: blink 1s steps(5, start) infinite;
  }