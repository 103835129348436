.app {
  height: 100%;
  margin-left: 30px;
}

.treeRoot {
  height: 100%;
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: #e8f0fe;
}
