.root {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 32px;
  padding-inline-end: 8px;
}

ul {
  list-style-type: none;
}

ol {
  list-style-type: none;
}

.expandIconWrapper {
  align-items: center;
  font-size: 0;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 8px;
}

.inputWrapper {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
}

.textField input {
  font-size: 14px;
  padding: 6px 0;
}

.editButton {
  padding: 6px;
}

.editIcon {
  font-size: 20px;
}

.nodeInput {
  width: 300px;
}

.nodeLabel {
  padding-right: 15px;
  width: 500px;
}
